var render = function render(){var _vm=this,_c=_vm._self._c;return _c('KTCodePreview',{attrs:{"title":'Kết quả báo cáo POSM'},scopedSlots:_vm._u([{key:"preview",fn:function(){return [_c('div',[_c('b-card',{staticClass:"card-custom",attrs:{"title":`Cửa hàng: ${_vm.submissionInfo.store.name} (${_vm.submissionInfo.store.shortName})`}},[_c('b-row',{staticClass:"mb-6"},[_c('b-col',{attrs:{"cols":"6"}},[_c('ul',[_c('b-card-text',[_vm._v("Tên chiến dịch: "+_vm._s(_vm.submissionInfo.campaign.name)+" ")]),_c('b-card-text',[_vm._v("Ngày bắt đầu: "+_vm._s(_vm.formattedStartDate)+" ")]),_c('b-card-text',[_vm._v("Ngày kết thúc: "+_vm._s(_vm.formattedEndDate))])],1)]),_c('b-col',{attrs:{"cols":"6"}},[_c('b-card-text',[_vm._v("Tại địa chỉ: "+_vm._s(_vm.submissionInfo.store.address)+" ")]),_c('b-card-text',[_vm._v("Người khởi tạo Form: "+_vm._s(_vm.submissionInfo.employeeName)+" ")]),_c('b-card-text',[_vm._v("Điền vào lúc: "+_vm._s(_vm.formattedPosmFormSubmissionDate)+" ")])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card-text',[_c('h3',[_vm._v("Bên ngoài:")])])],1)],1),_vm._l((_vm.submissionInfo
            .posmCategories.exteriorCategories),function(exteriorCategory,exteriorCategoryIndex){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataReceived),expression:"dataReceived"}],key:`exterior-categories-${exteriorCategory.name}-${exteriorCategoryIndex}`},[_c('b-card-text',[_c('h4',[_vm._v("Hạng mục: "+_vm._s(exteriorCategory.name))])]),_c('b-row',{staticClass:"mb-6"},_vm._l((exteriorCategory.posms),function(posm,posmIndex){return _c('b-col',{key:`exterior-category-${exteriorCategory.name}-${exteriorCategoryIndex}-${posm.name}-${posmIndex}`,staticClass:"mb-8",attrs:{"cols":"6"}},[(
                  _vm.ALLOWED_IMAGE_EXTENSIONS.some((token) =>
                    posm.data.includes(token),
                  )
                )?_c('b-card',{staticClass:"card card-custom shadow-lg",attrs:{"overlay":"","img-src":posm.data,"text-variant":"white","bg-variant":"dark"}},[_c('b-card-text',{staticClass:"text-right bg-dark d-inline-block rounded px-4 py-2",staticStyle:{"opacity":"0.8"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(posm.name))])])],1):_c('div',[_c('h5',[_vm._v(_vm._s(posm.name))]),_c('p',[_vm._v("Hình chưa được điền, hoặc kiểu loại dữ liệu không phù hợp.")])])],1)}),1)],1)}),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card-text',[_c('h3',[_vm._v("Bên trong:")])])],1)],1),_vm._l((_vm.submissionInfo
            .posmCategories.interiorCategories),function(interiorCategory,interiorCategoryIndex){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataReceived),expression:"dataReceived"}],key:`interior-categories-${interiorCategory.name}-${interiorCategoryIndex}`},[_c('b-card-text',[_c('h4',[_vm._v(_vm._s(interiorCategory.name))])]),_c('b-row',{staticClass:"mb-4"},_vm._l((interiorCategory.posms),function(posm,posmIndex){return _c('b-col',{key:`interior-category-${interiorCategory.name}-${interiorCategoryIndex}-${posm.name}-${posmIndex}`,staticClass:"mb-8",attrs:{"cols":"6"}},[(
                   _vm.ALLOWED_IMAGE_EXTENSIONS.some((token) =>
                    posm.data.includes(token),
                  )
                )?_c('b-card',{staticClass:"card card-custom d-flex flex-row-reverse",attrs:{"overlay":"","img-src":posm.data,"text-variant":"white","bg-variant":"dark"}},[_c('div',{staticClass:"text-right d-inline-block"},[_c('b-card-text',{staticClass:"text-right bg-dark d-inline-block rounded px-4 py-2",staticStyle:{"opacity":"0.8"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(posm.name))])])],1)]):_c('div',[_c('h5',[_vm._v(_vm._s(posm.name))]),_c('p',[_vm._v("Hình chưa được điền")])])],1)}),1)],1)})],2)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }