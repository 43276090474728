export default class Campaign {
  /**
   * @param {string} name
   * @param {string} startDate
   * @param {string} endDate
   */
  constructor(name, startDate, endDate) {
    this.name = name;
    this.startDate = startDate;
    this.endDate = endDate;
  }
}
