export default class PosmFormInfo {
  /**
   * @param {object} campaign
   * @param {string} employeeName
   * @param {object} store
   * @param {string} createdAt
   * @param {Object} posmCategories
   */
  constructor(campaign, employeeName, store, createdAt, posmCategories) {
    this.campaign = campaign;
    this.employeeName = employeeName;
    this.store = store;
    this.createdAt = createdAt;
    this.posmCategories = posmCategories;
  }
}
