export default class PosmCategories {
  /**
   * @param {PosmCategory[]} exteriorCategories
   * @param {PosmCategory[]} interiorCategories
   */
  constructor(exteriorCategories, interiorCategories) {
    this.exteriorCategories = exteriorCategories;
    this.interiorCategories = interiorCategories;
  }
}
